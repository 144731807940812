

const crypto = require("crypto");

const EncryptionHelper = (function () {
  function getKeyAndIV(key, callback) {
    crypto.pseudoRandomBytes(16, (err, ivBuffer) => {
      let keyBuffer = (key instanceof Buffer) ? key : new Buffer(key);

      callback({
        iv: ivBuffer,
        key: keyBuffer,
      });
    });
  }

  function encryptText(cipher_alg, key, iv, text, encoding) {
    const cipher = crypto.createCipheriv(cipher_alg, key, iv);

    encoding = encoding || "binary";

 
    let result = cipher.update(text, "utf8", encoding);
    result += cipher.final(encoding);

    return result;
  }

  function decryptText(cipher_alg, key, iv, text, encoding) {
    const decipher = crypto.createDecipheriv(cipher_alg, key, iv);

    encoding = encoding || "binary";

    let result = decipher.update(text, encoding, 'utf8');

    return result;
  }

  return {
    CIPHERS: {
      AES_128: "aes128", // requires 16 byte key
      AES_128_CBC: "aes-128-cbc", // requires 16 byte key
      AES_192: "aes192", // requires 24 byte key
      AES_256: "aes256", // requires 32 byte key
    },
    getKeyAndIV,
    encryptText,
    decryptText,
  };
}());



























module.exports = EncryptionHelper;
